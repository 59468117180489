import React from 'react'

function PrivacyPolicy() {
  return (


      <div className='py-5'>


        <div className='container'>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='dark-card-header'>
                <h2 className='card-title mb-4'>Service Provider Terms</h2>
              </div>
              <div className='page-content-div'>
                <p>When you sign up to be a Service Provider on the .................... App, you agree to be bound by
                  the Service Provider Terms, which cover details about aspects of the Service Provider-related App
                  Policy, which are the general terms governing your use of our Services in addition to the Service
                  Provider Terms mentioned in the Terms and Conditions.</p>
                <p>As a Service Provider, you are contracting directly with the App, (Company .....................),
                  regardless of whether another affiliate of ours facilitates payments to you.</p>

                <h4>Service Provider Obligations</h4>
                <ul className='ps-3'>
                  <li>As a service provider, you are responsible for all services you provide to the user at the
                    place where the service is provided, and you are responsible for the accuracy of the data,
                    information, images, audio recordings and video clips that you provide about yourself when
                    registering an account on the application, and you acknowledge that they are owned by you and
                    are private to you, and you are responsible for the conversations that take place between you
                    and the user whether through the application or private or direct messages, and in general you
                    are responsible for everything you publish or provide for publication on the application or is
                    provided through the application services and otherwise.
                  </li>
                </ul>

                <h5>
                  You represent and warrant that:
                </h5>
                <ul className='ps-3'>
                  <li>You will provide accurate account information when you create an account on the App.</li>
                  <li>You own or have the necessary licenses, rights, consents, permissions and authority to authorize
                    the App to use the Submitted Content as specified in these Terms and Conditions.
                  </li>
                  <li>The Content, Services, Images and Audio provided by you and owned by you will not infringe or
                    misappropriate the intellectual property rights of any third party.
                  </li>
                  <li>You have the qualifications, credentials and experience required to provide the Services we
                    provide through the App.
                  </li>
                  <li>You will ensure that the quality of the Service is consistent with the standards of the Services
                    we generally provide.
                  </li>
                </ul>

                <h5>
                  You warrant that you will not:
                </h5>
                <ul className='ps-3'>
                  <li>Post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false,
                    misleading, incorrect or defamatory content, information or service.
                  </li>
                  <li>Post or transmit any unwanted or unauthorized advertising, promotional materials, junk mail, spam
                    or any other form of advertising through the Services or to any user of the Application.
                  </li>
                  <li>Use the Services for business other than providing religious chanting, Quran recitation and
                    Islamic songs services to users.
                  </li>
                  <li>Engage in any activity that requires us to obtain licenses from any third party or any government
                    agency or pay fines or subscriptions to any third party.
                  </li>
                  <li>Impersonate another person or gain unauthorized access to another person's account.</li>
                  <li>Interfere with or prevent other service providers from providing their services or content.</li>
                  <li>Misuse the Application in any way.</li>
                </ul>

                <h4>2- License.</h4>
                <ul className='ps-3'>
                  <li>Unless otherwise agreed upon in a special written contract between the two parties, you grant the
                    application management the rights detailed in the terms and conditions to display, market and
                    exploit your services to users in any way, including the right to add captions or modify the audio
                    and/or video models provided by you in another way to ensure accessibility, and you also authorize
                    the application management to sublicense these rights to your submitted content to third parties,
                    including users directly and through third parties such as affiliated sites, promotional sites and
                    paid advertisements on any third party platforms.
                  </li>
                  <li>Unless otherwise agreed upon in a special written contract between the two parties, you do not
                    have the right to remove all or part of your profile page and the content registered therein or the
                    services you provide and added when registering the account except after obtaining the approval of
                    the application management.
                  </li>
                  <li>We may record and use all or any part of your information and the models provided by you for
                    quality control and to provide, market, promote, display or operate the services. You grant the
                    Application Administration permission to use your name, voice and images in connection with the
                    provision, marketing, promotion and sale of the Services or the Content provided, and you waive any
                    rights of privacy, publicity, or other rights of a similar nature, to the extent permitted by
                    applicable law.
                  </li>
                </ul>

                <h4>3- Trust and Security.</h4>
                <p>Trust and Security Policies.</p>
                <p>You agree to abide by the terms and conditions of the application and other service quality standards or policies determined by the application management from time to time, you must review these policies periodically to ensure your compliance with any updates made to them, you understand that your use of the services is subject to the approval of the application management, which we may grant or reject at our sole discretion.</p>
                <p>We reserve the right to remove the personal page and / or suspend payments and / or block service providers for any reason at any time, without prior notice, including in cases where:</p>

                <ul className='ps-3'>
                  <li>Service providers do not comply with our policies or legal terms (including the Terms of Use).
                  </li>
                  <li>The services they provide are below our quality standards or have a negative impact on the users’
                    experience.
                  </li>
                  <li>The service provider engages in behavior that may reflect negatively on the application or cause
                    the platform’s reputation to be damaged, disdainful, defamatory, or ridiculed.
                  </li>
                  <li>The service provider uses the services in a way that constitutes unfair competition, such as
                    promoting their business outside the application.
                  </li>
                  <li>Any other reasons determined by the application management at its sole discretion.</li>
                </ul>


                <p>Relationship with other users.</p>
                <p>The Service Provider does not have a direct contractual relationship with users, so the only information you will receive about the user is what is provided to you through the Services. You agree that you will not use the data you receive for any purpose other than providing your services to those users through the Application, and that you will not request additional personal data or store users' personal data outside the Application. You agree to indemnify the Application Administration against any claims arising from your use of users' personal data.</p>

                <h4>4- Pricing.</h4>

                <h5>Determining Prices</h5>
                <p>When creating your account page, the application will ask you to specify the base price for your services, and you acknowledge that the application management has the full right and absolute authority to modify the price by reducing or increasing it at its own discretion.</p>

                <h5>Transaction Taxes</h5>
                <p>If a user purchases content or a service in a country that requires us to remit national, state or local sales taxes, use taxes, value added taxes or any other taxes under applicable law, we will collect and remit the transaction taxes to the competent tax authorities for those sales.</p>
                <h5>Payments</h5>
                <p>When a user requests any of the services you provide through the application, we send you a notice to determine the final price and you must respond to us within the period mentioned in (Terms of Use), and immediately we calculate the total amount of the service as the amount that the user will actually pay us, and from this (total amount), we subtract the application’s share due to it estimated at (the platform’s share in sales operations is added) .... % of the value of each sale, and any taxes on transactions, and any fees to payment service providers, and any amounts paid to third parties in connection with promotional programs, in addition to deducting any other dues that are in your possession in favor of the application for previous refunds or fines or penalties or compensation or any other dues, so that we obtain (the net amount)</p>
                <p>If we change the application’s share in sales operations, we will provide you with a 30-day notice via your registered email.</p>
                <p>The application management will reserve what is .....% of (the net amount) referred to in the first paragraph of this item from each sale of your services, and we will keep it as a guarantee for any fines, compensation, penalties, damages, or any other dues or rights that you owe to the application or others as a result of actions or violations related to or connected to the services provided by you to users. The financial guarantee referred to in the third paragraph cannot be claimed until 6 months have passed since the service provider's account was closed on the application, and the application management has the full and absolute right to reserve the guarantee for an additional period or periods in the event of any legal disputes or claims from others related to the services provided by you until they are finally settled.</p>

               <h5> Payment of Service Provider Dues</h5>
                <p>The application management will pay the service provider dues for each purchase independently and separately.</p>
                <p>We will pay the dues represented by (the net amount) after deducting the financial guarantee as mentioned in the (Payments) clause.</p>
                <p>The due amount will be paid after twenty-four (24) hours from the specified time for the end of providing the service to the user.</p>
                <p>If there is an objection or complaint from the user, the payment process will be stopped and the total price of the service paid by the user will be withheld until the complaint is resolved by the application management.</p>
                <p>In order for us to pay you in a timely manner, you must have a bank account in your personal name, and you must provide your bank account details when you create your account on the platform, and you must notify us of any change in your bank account.</p>
                <p>Payment of the amounts due to the service provider shall only be by depositing into the bank account owned by him personally, and it is not permissible in any case to demand payment of dues by any other means or to any other bank account owned by a third party.</p>
                <p>The application administration pays all service provider payments in Kuwaiti Dinars (KWD) only, and we are not responsible for foreign currency conversion fees, bank transfer fees outside the State of Kuwait, or any other processing fees that you may incur. We reserve the right not to pay funds in the event of fraud, intellectual property rights violations, or other violations of the law.</p>

                <h5>Refunds</h5>
                <p>You acknowledge and agree that Users are entitled to a refund, as detailed in the Terms and Conditions. Providers will not receive any proceeds from transactions for which refunds have been made under the Terms of Use.</p>
                <p>If a User requests a refund after we have paid the relevant Provider, we reserve the right to either (1) deduct the refund amount directly from our Provider’s financial guarantee, (2) deduct the refund amount from the next payment sent to the Provider, or (3) require the Provider to refund any refunds to the User.</p>

                <h4>5- Brand.</h4>
                <p>
                  <b>As a registered service provider with us and subject to the requirements below, you may use our trademarks where we permit you to do so.</b>
                </p>
                <h5>You must:</h5>

                <ul className='ps-3'>
                  <li>Only use our trademark images that we provide to you.</li>
                  <li>Only use our trademarks in connection with the promotion and advertising of the services you
                    provide through the App.
                  </li>
                  <li>You will comply promptly if we ask you to stop using our trademarks.</li>
                </ul>
                <h5>You must not:</h5>
                <ul className='ps-3'>
                  <li>Use our trademarks in a misleading or offensive manner.</li>
                  <li>Use our trademarks in a manner that suggests we endorse, sponsor, or approve your services.</li>
                  <li>Use our trademarks in a manner that violates applicable law or in connection with obscene,
                    indecent, or unlawful subject matter or material.
                  </li>
                  <li>Use your own, or a third party’s, trademarks, or any other trademarks, when using the App.</li>
                </ul>


               <h4> 6- Deleting the Service Provider Account.</h4>

                <p>We will make commercially reasonable efforts to make any remaining scheduled payments due to you before deleting your account. You understand that if users have previously registered a complaint or objection regarding the services you provided through the application, all and/or part of your dues will be withheld until the complaint and objection are finally resolved.</p>

                <p>8. Various Legal Terms</p>

                <p>8.1 Updating these Terms</p>
                <p>From time to time, we may update these Terms, and the application management reserves the right to modify and/or make changes to these Terms, at any time at its sole discretion, if we make any material change, we will notify you using a notice via the email address specified in your account. The amendments will become effective on the day they are posted unless otherwise stated.</p>
                <p>Your continued use of our services after the changes become effective means that you accept these changes.</p>

                <h4>7- Language.</h4>
                <p>No version of these terms is provided in a language other than Arabic and you understand and agree that the Arabic language governs in the event of any conflict.</p>
                <h4>8- The relationship between us.</h4>
                <p>We and you agree that there is no joint venture, partnership, employment or agency between us.</p>
                <h4>9- Applicable law.</h4>
                <ol className='ps-3'>
                  <li>The service provider acknowledges the agreement to arbitrate to resolve disputes that arise between it and users regarding the provision of services, complaints, objections and refund requests related to the provision of the service, etc., and it was previously agreed to appoint the application management as the sole arbitrator in any dispute that may arise regarding this contract and its ruling shall be final.</li>
                  <li>Except as stated in Clause No. (1), these terms and your use of the application and any information contained therein and any dispute arising from this use of the application are subject to the laws of the State of Kuwait, and the Kuwaiti civil and criminal courts have exclusive jurisdiction.</li>
                </ol>
              </div>
            </div>

          </div>


        </div>

      </div>
  )
}

export default PrivacyPolicy