import React from 'react'

function TermsandCondition() {
  return (


      <div className='py-5'>


        <div className='container'>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='dark-card-header'>
                <h2 className='card-title mb-4'>Terms and Condition</h2>
              </div>
              <div className='page-content-div'>
                <p>
                  These terms and conditions apply to your use of the electronic application <span
                    className='text-danger'>(please add the name of the application)</span>
                </p>
                <p>
                  By using the ........................... application, you agree to be bound by these terms and
                  conditions. If you do not agree to the terms and conditions, you must not use the application.
                </p>
                <p>
                  We reserve the right to change the Terms and Conditions from time to time and you should review these
                  Terms and Conditions periodically. We are not obligated to contact you or notify you of any changes to
                  the Terms and Conditions. Your continued use of the .................... Application constitutes your
                  acceptance of the Terms and Conditions in effect at the time of your use of the Site.
                </p>

                <h3>Definitions</h3>
                <ul className='ps-3'>
                  <li>Application\ refers to the application of ................................</li>
                  <li>Service\ means the services provided by the service providers registered on the application.
                  </li>
                  <li>Service Provider\ refers to every reader and/or singer registered with us on the application
                    and the user can request him to present a religious segment in Islamic gatherings, conferences and
                    weddings.
                  </li>
                  <li>User\ refers to every person who creates an account on the platform in order to receive
                    content.
                  </li>
                  <li>Recorded Content\ refers to audio clips recorded by the service provider's voice and the
                    purpose of which is to inform the user of the service provider's performance.
                  </li>
                  <li>Attendance Recording\ is the process of the service provider recording his arrival at the
                    specified time to the agreed upon place of service provision.
                  </li>
                  <li>Service Place\ means the place specified by the user to obtain the service.</li>
                  <li>Approved Contact Number\ means the phone number and social media application (WhatsApp)
                    provided by the user when registering his data in the application and is considered the recognized
                    and approved means of communication by the user.
                  </li>
                  <li>Interactive Areas\ are special areas for communication between the user and the service
                    provider through text messages exchanged between them.
                  </li>
                  <li>Notifications/ messages sent by the application to the user via text messages on the social
                    networking application (WhatsApp) through the approved contact number.
                  </li>
                  <li>Objection/ means any complaint or objection submitted by the user</li>
                  <li>Support team/ is an employee of the application.</li>
                  <li>Fees/ means the financial consideration for the service</li>
                  <li>Fines/ means any fine imposed by the application on either the service provider or the user
                  </li>
                  <li>You/ the addressee formula "refers to the user of the platform.</li>
                  <li>We/ the speaker formula" means the application of .........</li>
                  <li>User information/ means the details and personal data that you provide to us when you create an
                    account on the application of ....................
                  </li>
                  <li>Automated processing/ means any written content analysis tools, data collection tools or other
                    tools, routines or automated processes.
                  </li>
                  <li>Terms and conditions/ means these terms and conditions, which may be subject to amendment or
                    change from time to time.
                  </li>
                </ul>

                <h3>Terms of use</h3>
                <p>
                  The mission of the application is to offer religious chanting services, reading the Holy Quran and
                  Islamic songs in religious gatherings, conferences and Islamic weddings through chanters and readers
                  who are experts in religious chanting and reading the Holy Quran and to enable people (users) to
                  access service providers, compare them, book appointments and ensure obtaining high-quality service.
                  We need rules to maintain our platform and provide safe services for you, us, our user community and
                  our service providers. Therefore, these terms apply to all your activities on the application and
                  other related services.
                </p>
                <p>
                  If you register as a Service Provider on the App, you must also agree to the Service Provider's terms.
                  We also provide details regarding our processing of personal data of our users and Service Providers
                  in our Privacy Policy.
                </p>
                <p>
                  If you live in any of the Gulf and Middle East countries, by agreeing to these terms, you agree to
                  resolve disputes with the application through the courts of the State of Kuwait, as detailed in the
                  Dispute Resolution section.
                </p>

                <h3>1- Create an account</h3>

                <p>
                  You need an account for all activity on the App. Keep your username and password safe, as you are
                  responsible for all activity associated with your account. If you suspect that someone else is using
                  your account, let us know by contacting our support team.<br/>

                  You need an account for all activity on our platform, including purchasing and accessing the Service.
                  When you set up your account, you must provide and continue to provide accurate and complete
                  information, including a valid email address and your WhatsApp phone number. You are fully responsible
                  for your account and everything that happens on your account, including any harm (to us or anyone
                  else) caused by someone using your account without your permission. This means that you need to be
                  careful about your password and username. You may not transfer your account to another person or
                  enable someone else to use your account, nor may you use someone else’s account. If you contact us to
                  request access to your account or provide you with a Live Streaming Services code, we will not grant
                  you access or code unless you can provide us with the information we need to prove that you are the
                  owner of that account. You may not share your account login credentials with anyone else. You are
                  responsible for what happens to your account and the application will not intervene in disputes
                  between users and service providers who have shared account login credentials with each other or with
                  other persons. You must notify us immediately if you become aware that someone else may be using your
                  account without your permission (or if you suspect any other breach of security) by contacting our
                  support team. We may ask you for some information to confirm that you are the actual owner of your
                  account.<br/>
                  When creating an account, and in order to communicate with you during your use of the services, you
                  must provide your correct personal data, your email, and the personal phone number through which you
                  use the social networking application (WhatsApp).<br/>
                  Since notifications are sent to you through your account on the application and/or your email and/or
                  the social networking application (WhatsApp), you must notify us immediately of any change that occurs
                  to you in the aforementioned means of communication.<br/>

                  You can terminate your account at any time by following the prescribed steps.<br/>
                </p>

                <h3>2- Rules of legitimate use.</h3>

                <ol className='ps-3'>
                  <li>
                    You acknowledge and undertake the accuracy of the data registered by you on the application as
                    stipulated in these terms and conditions.
                    <ul>
                      <li>(a) You will not copy, download, reproduce, republish, broadcast, or send in any way any of
                        the promotional materials available on the application
                      </li>
                      <li>(b) You will abide by the specific rules that apply to any promotion in which you participate
                        in any way through the application
                      </li>
                      <li>(c) You undertake not to do anything that would affect the operation or security of the
                        application or cause unreasonable annoyance, abuse or disruption to any other users or our
                        employees.
                      </li>
                    </ul>
                  </li>

                  <li>
                    2- You agree to refrain from the following (whether you do it personally or through a third party):
                    <ul>
                      <li> (a) Use an automated process to process, monitor, copy, or extract any pages on the
                        application, or any information, content or data contained in or accessed through the platform
                        without obtaining prior written consent from us.
                      </li>
                      <li> (b) Use an automated process to collect or combine information, content or data contained in
                        or accessible through the Application, or materials or data accessed through or from a third
                        party.
                      </li>
                      <li> (c) Use any automated process to interfere with or attempt to interfere with the proper
                        working of the Application.
                      </li>
                      <li> (d) Do anything that imposes an unreasonable or disproportionately large load on the
                        infrastructure available to the Application or implement reverse engineering, reverse assembly,
                        disassembly, or any other act that discovers the source code or other algorithms or processes in
                        relation to the computer software used in the infrastructure and operations related to the
                        Application.
                      </li>
                      <li> (h) Copy, reproduce, modify, adapt, create derivative works from, or publicly display, any
                        part of the Application Content without our prior written consent.
                      </li>
                    </ul>
                  </li>
                  <li>3- In order to access the Application Services, you will need access to the Internet. You are
                    responsible for any connection, service or fees associated with accessing the Internet and for
                    providing all equipment necessary to enable you to connect to the Internet (including a computer,
                    modem and other devices necessary for access).
                  </li>
                  <li>4- If you are a service provider, you can only use the application for lawful purposes. You are
                    responsible for the audio content, photographs, and other content that you publish on the
                    application, and you must respect the intellectual property rights of others. The platform has the
                    right to block your account or any of your content due to repeated or serious violations. You are
                    prohibited from publishing any expressions that contain abuse or racist references.
                  </li>
                  <li>5- If you are a service provider, you must abide by the law and respect the rights of others: You
                    may not publish any audio clip, photographs, or any other content that violates local or national
                    laws or regulations in force in the State of Kuwait.
                  </li>
                  <li>6- If you are a service provider, you are solely responsible for the content of the religious
                    hymns or Islamic songs you provide during the provision of the service without any responsibility on
                    the application.
                  </li>
                  <li>7- If we are notified that your service that you provide to the user violates the law or the
                    rights of others (for example, if it is proven that it violates the intellectual property rights or
                    image rights of others, or is related to an illegal activity), or if we discover that your content
                    or behavior violates any of our terms, conditions and rules or offends others or harms the
                    reputation of the application, or if we believe that your content or behavior is illegal,
                    inappropriate or objectionable (for example if you impersonate someone else), we may permanently
                    close your account with the application of the fines stipulated. 8- The application management has
                    the discretion to enforce these terms and we may restrict or terminate your permission to use the
                    application and our services or block your account at any time, with or without notice, for any
                    reason or no reason, including any violation of these terms, if you fail to pay any fees when due,
                    for fraudulent refund requests, upon request by the application of the law of the State of Kuwait,
                    for long periods of inactivity, or for unexpected technical problems or problems, if we suspect that
                    you are participating in fraudulent or illegal activities, or for any other reason at our sole
                    discretion, upon any such termination, we may delete your account and your content, and we may
                    prevent you from accessing the platforms and using our services.
                  </li>
                  <li>9- If any part of these terms is found to be invalid or unenforceable under applicable law, such
                    provision will be deemed replaced by a valid and enforceable provision that most closely matches the
                    intent of the original provision and the rest of these terms will remain in effect.
                  </li>
                  <li>10. Even if we delay or fail to exercise a right in one instance, this does not mean that we are
                    waiving our rights under these Terms, and we may decide to enforce them in the future. If we decide
                    to waive any of our rights in a particular instance, this does not mean that we are waiving our
                    rights generally or in the future.
                  </li>
                </ol>


                <h3>3- Terms and conditions specific to service providers and their registration on the
                  application.</h3>
                <ol className='ps-3'>
                  <li>By creating a service provider account, a personal page will be allocated to you on the
                    application.
                  </li>
                  <li>You must provide a copy of your civil ID and/or a copy of your passport and/or any other
                    personal identification data.
                  </li>
                  <li>You must provide accurate data, correct information, a valid phone number, and your account
                    number on the social networking site (WhatsApp)
                  </li>
                  <li>You must provide recorded content from your previous work and high-quality personal photographs
                    when registering the account, and your submission of the recorded content and photographs is
                    considered.
                  </li>
                  <li>The service provider from outside the State of Kuwait must provide official data and
                    certificates that prove the validity of their identification documents.
                  </li>
                  <li>You must follow up on the notifications sent to you regarding confirmation of the service
                    reservation
                    dates requested by users, and you must respond and confirm or reject them within two hours of
                    submitting
                    the request.
                  </li>
                  <li>When confirming the service request, you are obligated to go to the service site at the
                    specified time, otherwise the application has the right to impose the fines agreed upon in the fines
                    schedule within these terms and conditions.
                  </li>
                  <li>Once you reach the service location, you must prove your attendance through the application.
                    The fixed attendance registration date in the application is the date that is considered to prove
                    your attendance registration, and you alone bear responsibility for not registering your attendance
                    upon your arrival at the service location.
                  </li>
                  <li>You must commit to providing the required service with high quality and efficiency, and you are
                    committed to treating the user well and committing to providing the service during the entire agreed
                    period.
                  </li>
                  <li>10- Once you register in the application, you grant us a non-exclusive, free, perpetual,
                    transferable, worldwide, irrevocable, and sublicensable license to use, reproduce, modify, edit,
                    adapt, translate, distribute, publish, copy, broadcast and communicate it in any form, and in works
                    adapted from it to display or perform it before an audience anywhere in the world and through any
                    media, now known or invented in the future, and to use the name you provided in connection with the
                    content. You also grant us a license to pursue any natural or legal person who violates your or our
                    rights in this content. You acknowledge and agree that all content provided to the application by
                    you is non-confidential and non-proprietary. You also acknowledge all things and execute all
                    documents required to enable us and allow us to benefit from the full rights granted to us according
                    to the requirements of these terms and conditions. 44- We are not obligated, and no provision of
                    these terms and conditions obligates us in any way, to use any content. In addition, we are not
                    required, in the event of using the content, to perform any financial obligations towards you as a
                    result of this use that were not agreed upon before publication.
                  </li>

                  <li>
                    interactive areas, including the areas for publishing registered content and messages exchanged
                    between you and the user, and all use of them is at your own risk. By using the interactive
                    areas, you agree not to publish, upload, send, distribute, store, create or publish in any way,
                    or cause to be published on the application directly or indirectly through a third party website
                    any of the following:
                    <ul>
                      <li>(a) Content that is illegal, defamatory, libelous, offensive to any religious or moral group,
                        obscene, pornographic, vulgar, indecent, suggestive, harassing, threatening, infringing on
                        privacy and publicity rights, violent, inflammatory, fraudulent or otherwise unacceptable.
                      </li>
                      <li>(b) Content that constitutes, encourages or provides instructions for a criminal offense,
                        violates the rights of any party, or in any way causes legal liability or violates any local,
                        national or international laws.
                      </li>
                      <li>(c) Content that is harassing or disruptive to others.</li>
                      <li>(d) Content that you do not personally own without the express permission of the owner of such
                        Content.
                      </li>
                      <li>(e) Content that may infringe any patent, trademark, trade secret, copyright, or other
                        intellectual or proprietary right of any party.
                      </li>
                      <li>(h) Content that impersonates any person or entity or otherwise falsely misrepresents
                        affiliation with such person or entity.
                      </li>
                      <li>(g) Unsolicited promotional materials, political campaigning, advertising, contests,
                        sweepstakes, or solicitations.
                      </li>
                      <li>(d) Third party information, including, but not limited to, last name, address, telephone
                        numbers, email addresses, and credit card numbers.
                      </li>
                      <li>(i) Viruses, corrupted data, or other files that cause damage, disruption, or destruction.
                      </li>
                      <li>(r) Content or links to Content that we, in our sole judgment, determine to violate any of the
                        above provisions; Or unacceptable, or restricts or prevents any other person from using or
                        enjoying the application, or causes us or any of the service providers or users to be exposed in
                        any way to harm or legal accountability in any way.
                      </li>
                    </ul>
                  </li>
                  <li>We do not bear responsibility or endorse any content published by users or service providers, or
                    uploaded in, or broadcasted, distributed, stored, or found, or published in any way or caused to be
                    published on the application or through it or because of it.
                  </li>
                  <li>We do not bear responsibility for any messages, content, phrases or actions that occur from you or
                    against you during real personal conversations while providing the service or private text messages
                    using means outside the interactive areas or through them that are between you and the user.
                  </li>
                  <li>We do not bear any responsibility for any content published, stored or uploaded to your personal
                    page on the application by you or any third party, or for any loss or damage resulting from that,
                    and we do not bear any responsibility for any errors, defamation, contempt, libel, omissions, lies,
                    abuse, pornography, or insults that you may encounter while using the application or the interactive
                    or personal areas between you and users. We also do not bear any responsibility for any statements,
                    pledges or content in the interactive areas. Although we are not obligated to sort, edit or follow
                    up on any of the content that is published or distributed through the interactive area.
                  </li>
                </ol>


                <h3>4- Registering the service request and the terms and conditions for obtaining it.</h3>
                <ul className='ps-3'>
                  <li>A- When the user submits a request to obtain a service from one of the service providers
                    registered with us, you must specify the day, hour and duration required through the options
                    provided in the application.
                  </li>
                  <li>B- We will immediately send a notification to the service provider regarding the request submitted
                    by you to respond to it with approval, rejection or request for modification within two hours of
                    registering the request.
                  </li>
                  <li>C- We will send a notification to the user via the approved contact number to confirm or reject.
                  </li>
                  <li>D- When confirming the reservation request, you must pay the fees due within two hours from the
                    time of notification, otherwise the request will be considered void and you must submit a new
                    request.
                  </li>
                  <li>C- In the event that either party (the user or the service provider) wishes to request
                    cancellation and/or modification of the service delivery times, notification must be made 48 hours
                    before the service delivery time.
                  </li>
                  <li>H- You must, within five (5) hours of the agreed start and/or end time of the service, notify the
                    support team in writing if the service provider does not show up on the specified date and/or if the
                    service provided is not at the required level and/or if the service is not provided during the
                    entire agreed period and/or if you have any other complaints. If the complaint is not submitted
                    within the specified period, your right to object will be forfeited.
                  </li>
                  <li>G- When submitting the complaint within the specified date, we will examine and investigate the
                    complaint, and the decision will be issued by the application management as an arbitration body and
                    its ruling will be final and not subject to appeal.
                  </li>
                  <li>D- You acknowledge as a user that the application management is not responsible for any messages,
                    content, phrases or actions that occur from you or against you during real personal conversations
                    while providing the service or private text messages using means outside the interactive areas or
                    through them that are between you and the service provider.
                  </li>
                  <li>I- When you request a service provider from outside Kuwait, you must provide housing, food, drink
                    and everything necessary for his stay inside Kuwait during the days of providing the service.
                  </li>
                </ul>

                <h3>5- Payments and refund policy.</h3>
                <p>When you make a payment, you agree to use a valid payment method in accordance with the following
                  terms and conditions:</p>
                <h5>3.1- Pricing.</h5>

                <ul className='ps-3'>
                  <li> Service prices are determined based on the advertised prices and/or a special offer from the
                    Service Provider and/or our own promotional policy.
                  </li>
                  <li> We occasionally run promotions for the Services provided, during which a particular Service is
                    available at discounted prices for a specific period of time. The price applicable to the Service
                    will be the price at the time you complete your purchase. In the event of a change in the date, the
                    promotion will be considered void and the regular Service prices will apply.
                  </li>
                  <li> All financial transactions on the Platform are in Kuwaiti Dinars.</li>
                  <li> If you are a User located in a country where use and sales tax, goods and services tax or value
                    added tax is applicable on consumer sales, we are responsible for collecting that tax and remitting
                    it to the appropriate tax authorities. Depending on your location, the price you see may include
                    such taxes.
                  </li>
                </ul>
                <h5>3.2- Payment.</h5>

                <ul className='ps-3'>
                  <li>You agree to pay the Service Fees you purchase, and you authorize us to charge your debit or
                    credit card or process other payment methods or direct debit from your account balance with the App,
                    to collect the fees. The App works with payment service providers to provide you with the most
                    convenient payment methods in your country and to keep your payment information secure. We may
                    update your payment methods using information provided by our payment service providers. Check our
                    Privacy Policy for more details. When you make a purchase, you agree not to use an invalid,
                    unauthorized or illegal payment method. If your payment method fails and you still have access to
                    the content you are registering for, you agree to pay us the corresponding fees within (24) hours of
                    our notification of the failure of the collection process. We reserve the right to cancel your
                    Service appointment reservation if we do not receive the full price scheduled for it.
                  </li>
                </ul>

                <h5>3.3- Refund.</h5>
                <ul className='ps-3'>
                  <li>
                    You must submit a cancellation and refund request 48 hours before the scheduled start of the service
                    appointment.
                  </li>
                  <li>If you are not satisfied with the service or have a complaint about it, you must submit the
                    complaint and objection within the deadlines mentioned in Clause (h) of Article (4), otherwise your
                    right to object will lapse and you may not request a refund. It is known that the application is not
                    obligated to refund the amount in this case except based on its final decision regarding the
                    complaint and objection. If the objection is rejected, you are not entitled to a refund of the
                    amount paid.
                  </li>
                  <li>This refund option does not apply to a service requesting a service from outside the State of
                    Kuwait.
                  </li>
                  <li>We reserve the right to apply the refunded amount as a refunded balance or a refund to the
                    original payment method, at our discretion, depending on the capabilities of our payment service
                    providers, the platform from which you purchased the service and other factors.
                  </li>
                  <li>As detailed in the trainer's terms, service providers agree that users have the right to recover
                    the refunded amounts in accordance with the terms and conditions set forth herein.
                  </li>
                  <li>If we decide to issue refunded balances to your account, they will be automatically applied to the
                    next service purchase on the application.
                  </li>
                  <li>If we decide to approve your cashback request within the above deadlines, the cashback will be
                    credited to the bank account from which the payment was made within 60 days of the date of approval
                    of the cashback request, and under no circumstances may the refund be claimed by any other means or
                    to any bank account other than the account from which the payment was made.
                  </li>
                </ul>

                <h5>3.4- Gifts and Promotion Codes.</h5>
                <ul className='ps-3'>
                  <li>The Application or Service Providers may offer gifts and promotional codes to Users, which may
                    then be used to purchase Services, subject to the terms set forth in each gift or promotional code.
                  </li>
                  <li>Such gifts and promotional codes may expire if not used within the specified gift or code period,
                    and gift and promotional codes provided by the Platform may not be redeemed for cash.
                  </li>
                </ul>

                <h3>Application rights and responsibilities.</h3>

                <ul className='ps-3'>
                  <li>
                    We own the App platform and services, including the current or future Website, Apps, Services, and
                    things like our logos, APIs, code, and content created by our employees. You may not tamper with or
                    use them without permission.
                  </li>
                  <li>All rights, title and interest in and to the Application and its Services, including our website,
                    our current or future applications, APIs, databases, and content provided by our employees or
                    partners or made available through our Services (but excluding content provided by Service
                    Providers) are and will remain the exclusive property of the Application and its licensors.
                  </li>
                  <li>Our App and Services are protected by copyright, trademark, and other laws of the State of Kuwait.
                    Nothing hereby gives you a right to use the App name or any of the App’s trademarks, logos, domain
                    names, and other distinctive brand features. Any feedback, comments, or suggestions you may provide
                    to the App or Services are entirely voluntary and we will be free to use such feedback and comments.
                  </li>
                </ul>
                <p>
                  <b>There are no guarantees for the information on this application.</b>
                </p>
                <ul className='ps-3'>
                  <li>You agree that your use of and reliance on the information on the Application is entirely at your
                    own risk. You acknowledge that while we endeavour to ensure the accuracy of the information on our
                    Site, we make no representation or warranty that the information provided is accurate or up-to-date.
                  </li>
                  <li>All warranties, whether express or implied, as to the accuracy, currency, completeness, fitness
                    for purpose or usefulness of the information on the Application are excluded to the extent permitted
                    by law.
                  </li>
                  </ul>

                <h4>Exclusion of our liability.</h4>

                <ol className='ps-3'>
                  <li> We do not employ Service Providers and are not responsible for any interactions between them and users. We are not responsible for disputes, claims, losses, injuries or damages of any kind that may arise from or relate to the conduct of Service Providers or users.</li>
                  <li> When you interact directly with a Service Provider or a user, you should be careful about the types of personal information you share. We do not control what users and Service Providers do with the information they obtain from the application. You should not share your email or any other personal information about yourself for your own safety.</li>
                  <li> By using the Services, you may be exposed to content that you consider offensive, indecent or objectionable. We are not responsible for such content provided by the Service Provider during the period of providing the Service and we are not responsible for the actions exchanged between you during that period. You acknowledge the risks inherent in the strenuous nature of these types of Services, and by accessing such Content, you voluntarily choose to assume those risks, including the risks of illness, bodily injury, disability or death. You assume full responsibility for the choices you make before, during and after you receive the Service.</li>
                  <li>
                    You acknowledge and agree to the following:
                    <ul>
                      <li>To the maximum extent permitted by law, any and all liability of us, our directors, officers and employees to you under or in connection with these Terms or your use of the App is excluded.</li>
                      <li>The above exclusion applies immediately regardless of whether the liability arises in contract, tort (including negligence), equity, statute or other legislation, and whether such liability relates to direct, indirect, consequential or punitive losses or damages, or loss of profit, income, data, business opportunity or anticipated savings.</li>
                    </ul>
                  </li>


                  <li>It is up to you to ensure that the device you use to access the Application is secure and that you are protected against viruses, spyware, malware or other elements of a destructive or harmful nature.</li>
                  <li>It may happen that the Application is down, either due to planned maintenance or because something happens with the Site. It may happen that one of the service providers makes misleading statements on their personal page or interactive areas. It may also happen that we encounter security issues. These are just examples. You accept that you will have no recourse against us in any of these types of cases where things do not go right.</li>
                  <li>We are not responsible for delays or failures in providing the Services due to events beyond our reasonable control, such as an act of war or hostility or sabotage, a natural disaster, a power outage or internet or communications, government restrictions, or any other emergency circumstances beyond our control</li>
                  <li>If you act in a way that exposes us to legal trouble, we may exercise legal recourse against you. You agree to indemnify us (if we so request) and your indemnification obligation will survive the termination of these Terms and your use of the Services.</li>
                  <li>
                    You agree to hold the Application and our group companies, their officers, directors, suppliers, partners and agents harmless from any third party claims, demands, losses or expenses (including reasonable attorneys' fees) arising from:
                    <ul>
                      <li>(a) the content provided by the Service Provider in the course of providing the Service (b) your use of the Services (c) your violation of these Terms (d) your violation of any third party rights.</li>
                    </ul>
                  </li>


                </ol>


                <h3>Applicable law.</h3>
                <ol className='ps-3'>
                  <li>Both the service provider and the user agree to arbitrate to resolve disputes that arise between them regarding the provision of services, complaints, objections, refund requests related to the provision of the service, etc., and it was agreed in advance to appoint the application management as the sole arbitrator in any dispute that may arise regarding this contract, and its ruling shall be final.</li>
                  <li>Except for what is mentioned in Clause No. (1), these terms and your use of the application and any information contained therein and any dispute arising from this use of the application are subject to the laws of the State of Kuwait, and the Kuwaiti civil and criminal courts have exclusive jurisdiction.</li>
                </ol>





              </div>
            </div>

          </div>


        </div>

      </div>
  )
}

export default TermsandCondition