import React from 'react'

function PrivacyPolicy() {
  return (


      <div className='py-5'>


        <div className='container'>

          <div className='row'>
            <div className='col-lg-12'>
              <div className='dark-card-header'>
                <h2 className='card-title mb-4'>Privacy Policy</h2>
              </div>
              <div className='page-content-div'>
                <p>We respect your privacy and want you to understand how we collect, use and share your data. This Privacy Policy covers our data collection practices and describes your rights regarding your personal data.</p>
                <p>Unless we link to a different policy or state otherwise, this Privacy Policy applies when you visit or use the ........... App or related services, and also applies to potential customers of our business products and organizations.</p>
                <p>
                  <b>By using the Services, you agree to the terms of this Privacy Policy. You must not use the Services if you do not agree to this Privacy Policy or any other agreement governing your use of the Services.</b>
                </p>
                <p>
                  This Privacy Notice describes how we collect, hold and use your personal information, and we are committed to achieving this purpose in accordance with the applicable laws in force in the State of Kuwait when collecting, using or handling any of your personal information.
                </p>

                <p>
                  <b>What is personal information?</b>
                </p>
                <p>
                  Personal information is any information about an individual that can be identified, so it includes your name, date of birth or any information that identifies you as an individual.
                </p>

                <p><b>How do we collect personal information?</b></p>
                <p>Like many other organizations, when you use our App and digital technologies, these organizations may automatically collect certain information about you or how you use those technologies. Sometimes, these technologies can help us understand you better. This means we can provide you with more relevant information and a better experience. However, we only use your personal information for legitimate reasons related to our business and activities. We collect personal information in a variety of ways, including:</p>

                <ol className='ps-3'>
                  <li>
                    Information you provide to us
                    <ol>
                      <li>The registration and acceptance processes require users and service providers to provide personal information, when you send us email or other communications, we may use the communications to process your inquiries, respond to your requests and improve our services.</li>
                    </ol>
                  </li>
                  <li>
                   Automatically collect usage information
                    <ul>
                      <li>When you interact with the App or our digital systems, we may also collect information about you through cookies and third party technologies. This may include:</li>
                      <li className='text-danger'>(Please consult the application programmer to find out the mechanisms for collecting information, if any, and adding it here in order).</li>
                      <li>
                        • (Please review the programmer of the application and our digital systems may also depend on us providing certain information we collect about you, how you interact with the application and our digital systems and the information you choose to enter, to third-party technology providers. This information will be used in accordance with the privacy practices of that third party. For example, they may use this information for the purposes of making their technology available, providing outputs (which may affect the user experience), ensuring quality, security, preventing abuse, and offensive content, in order to further develop and enhance the technology, troubleshooting, and resolving technical issues. Application to know the mechanisms of collecting information, if any, and adding them here in order).
                      </li>
                    </ul>
                  </li>





                </ol>

                <p>
                  <b>Cookies and Similar Technologies</b>
                </p>
                <p>
                  A cookie is a small amount of data that is sent to your browser from a web server and stored on your computer, and then sent back to the server by your browser each time you access certain sections of our websites and digital technologies. We may provide tools on our websites that allow you to manage your preferences regarding how we use cookies to collect data, including personal information. In addition, many commonly used browsers either have functionality or allow third-party functionality, which allows you to block, disable, restrict or delete cookies. If you wish to do this, please refer to your browser specifications.
                </p>
                <p className='text-danger text-center'>
                  <b>(Please check the formula with the programmer)</b>
                </p>

                <p>
                  <b>Other types of information</b>
                </p>

                <p>
                  For service providers/official identification documents - civil/national number - employment history, details, achievements, professional qualifications, accreditation documents - and other matters related to the application activity and the content provided on it
                </p>

                <p><b>How do we use personal information?</b></p>

                <p>We need to collect personal information for various purposes to carry out our functions and activities, however, we will only use your personal information for legitimate reasons related to our functions and activities.</p>

                <p><b>The purposes for which we may use your personal information include:</b></p>
                <ul className='ps-3'>
                  <li>To help us understand your preferences and interests when you seek to use our Services.</li>
                  <li>To communicate with you, or your emergency contacts.</li>
                  <li>To provide you with support, guidance and assistance in connection with any of our activities.
                  </li>
                  <li>To provide a reliable and secure environment on the App.</li>
                  <li>To send you information about service providers and content that may be of interest to you.</li>
                  <li>To provide a more relevant service or experience to you.</li>
                  <li>For our marketing and promotional activities.</li>
                  <li>To analyse (including automated analysis) and create a profile based on your characteristics and
                    interactions, so that we can more effectively deliver tailored and relevant communications to you
                    (including for our marketing and promotional activities)
                  </li>
                  <li>To verify your qualifications, academic credentials, achievements, character or suitability to
                    provide the Service and/or use the Services provided by the App.
                  </li>
                  <li>To stay in touch with you after the Service has been provided</li>
                  <li>To administer and operate the App, including any change or reorganisation thereof.</li>
                  <li>For our research and development purposes.</li>
                  <li>To monitor and evaluate performance</li>
                  <li>To store and preserve</li>
                  <li>To investigate and address any complaint, grievance, concern, legal action or any actual or
                    suspected misconduct or breach of our policies or code of conduct.
                  </li>
                  <li>To comply with any legal or contractual obligations.</li>
                  <li>To comply with any direction or request from the Government of the State of Kuwait.</li>
                </ul>

                <p><b>In circumstances where we need to use your personal information for purposes other than those described (or directly related to the purposes described), we will only do so in accordance with applicable law in the State of Kuwait.</b></p>
                <p><b>What personal information do we collect?</b></p>
                <p><b>We may collect the following types of personal information about you:</b></p>




                {/* page 3 */}
                <ul className='ps-3'>
                  <li>Name, address, gender, date of birth and age.</li>
                  <li>Contact details, such as your address, email address and phone number.</li>
                  <li>For service providers/official identification documents – civil/national ID number – employment
                    history, details, achievements, professional qualifications, credentials – and other things related
                    to the application activity and the content provided on it.
                  </li>
                  <li>Your interests, preferences, behaviors and aspirations</li>
                  <li>Your voice and image (including photographs, films or recordings)</li>
                  <li>Financial information, which may include bank account details (for the purposes of making payments
                    or receiving payments from you), and tax information.
                  </li>
                  <li>Your interactions with us, our employees, or technology (such as our systems and the
                    application)
                  </li>
                  <li>Anything else you choose to share with us or make available to us.</li>
                </ul>

                <p>
                  <b>We may need to collect and request a range of personal information about you so that we can help
                    you. What we collect depends on the circumstances. However, any personal information we collect will always be for a legitimate reason related to our business and activities. We do not sell your personal information to others.</b>
                </p>
                <p>
                  <b>Who do we share personal information with?</b>
                </p>
                <p>
                  We will only share your personal information:

                </p>

                <ul className='ps-3'>
                  <li>For the purpose for which it was collected (or for a purpose directly related to it)</li>
                  <li>For any other purpose you have stated</li>
                  <li>Where we are permitted or required to do so by law.</li>
                </ul>

                <p>
                  <b>We may share the personal information we collect about you for the purposes described in this Privacy Notice with:</b>
                </p>
                <ul>
                  <li>Our employees, agents and service providers registered on the App.</li>
                  <li>Our related organizations.</li>
                  <li>Service providers we have contracted with either to provide a service to us or to make a
                    service/tool ​​available to you. For example, we may share information about you with a service
                    provider we have engaged to provide technology services or customer support services.
                  </li>
                  <li>Payment service providers.</li>
                  <li>Judicial and law enforcement authorities.</li>
                  <li>Anyone you have authorized us to.</li>
                </ul>

                <p><b>When we share your personal information with others, it may be collected, held, used, disclosed and maintained in accordance with their privacy practices.</b></p>

                <p><b>How long do we store personal information?</b></p>
                <p>We only retain your personal information for as long as we have a good reason to retain it.</p>
                <p><b>How do we store personal information?</b></p>

                <p><b>We may store the personal information we collect:</b></p>
                <ul>
                  <li>In digital or non-digital formats (for example, within physical filing systems)</li>
                  <li>On our premises or off-site</li>
                  <li>Either directly, with relevant entities or through our service providers (including service
                    providers who provide physical or digital data storage facilities)
                  </li>
                  <li>Inside or outside Kuwait.</li>
                </ul>

                <p><b>Disclosure of Personal Information Outside Kuwait.</b></p>

                <p>When we make your personal information available to recipients located outside Kuwait, you acknowledge and agree that they may not be required to protect your personal information in a manner that generally provides safeguards similar to those provided by the laws of the State of Kuwait.</p>

                <p>
                  <b>Protection of Personal Information.</b>
                </p>
                <p>
                  We have reasonable security safeguards to protect your personal information from loss, unauthorized access, use, modification, disclosure, or other misuse as required by law, however, as with any other organization, there is always a risk that our systems may be compromised.
                </p>

                <p><b>Your Rights</b></p>
                <p>You may request that we not make available or correct your personal information that we hold. You must submit these requests to the Application Administration, and if there is a reason under applicable law that prevents us from complying with your request, we will notify you in writing and explain the reasons for the refusal.</p>

                <p><b>When you choose not to provide personal information to us, it may mean:</b></p>

                <ul className='ps-3'>
                  <li>We cannot continue to provide certain services or features to you.</li>
                  <li>It affects how we deal with and provide services to you.</li>
                  <li>Delays and/or inefficiencies may occur.</li>
                </ul>
                <p><b>Updates to this Privacy Notice</b></p>
                <p>We reserve the right to update this Privacy Notice from time to time. Any changes we make will be effective from the date we post the updated Privacy Policy on our website.</p>

                <p><b>Applicable Law.</b></p>
                <p>These Terms, your use of the Application, any information contained therein, and any dispute arising in connection with the Privacy Policy as a result of the use of the Application are subject to the laws of the State of Kuwait, and the Kuwaiti civil and criminal courts shall have exclusive jurisdiction to adjudicate them.</p>

              </div>
            </div>

          </div>


        </div>

      </div>
  )
}

export default PrivacyPolicy